const v3FeatureFlags = {
  views: {
    AcknowledgedPass: false,
    AdminGuide: false,
    Dashboard: false,
    ForgottenPassword: false,
    Locked: false,
    Login: false,
    QuickReference: false,
    ResetPassword: false,
    SubstituteUsers: true,
    TeacherGuide: false,
    Users: false
  },
  components: {
    HeaderActions: true,
    Banners: false
  }
}

const isV3Active = () => {
  // here you can add extra conditions
  // prod condition for switching from v3 to v2 (for everything):
  // return !appConfig.isProd
  return true
}
const isV3ViewVisible = (viewName = "") => {
  return !isV3Active()
    ? false
    : Object.hasOwn(v3FeatureFlags.views, viewName) &&
        v3FeatureFlags.views[viewName]
}

const isV3ComponentVisible = (componentName = "") => {
  return !isV3Active()
    ? false
    : Object.hasOwn(v3FeatureFlags.components, componentName) &&
        v3FeatureFlags.components[componentName]
}

export { isV3ComponentVisible, isV3ViewVisible }
