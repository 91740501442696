import axios from "axios"
import helpers from "../../helpers"
import { Promise } from "core-js"
import { apiV7nocache } from "./axiousWithCache"

const state = {
  passes: null,
  periods: null,
  passHistories: null,
  pass_limit: {},
  app_pass_limit: {},
  activePass: null,
  passesTimeSetting: null,
  transparencies: null,
  transparencyStatus: false,
  studentPassHistories: null
}

const mutations = {
  SET_PASSES(state, passesCollection) {
    state.passes = passesCollection
  },
  SET_PERIODS(state, periodsCollection) {
    state.periods = periodsCollection
  },
  SET_PASS_HISTORIES(state, passCollection) {
    state.passHistories = passCollection.map((e) => {
      e.badge_flags = helpers.getBadgeFlags(e)
      e.total_time = helpers.getTotalTime(e)
      e = helpers.setUserAssignedRoomsPerPass(e)
      return e
    })
  },
  PUSH_PASS_HISTORIES(state, passesCollection) {
    passesCollection.map((e) => {
      e.badge_flags = helpers.getBadgeFlags(e)
      e.total_time = helpers.getTotalTime(e)
      e = helpers.setUserAssignedRoomsPerPass(e)
      return e
    })
    if (state.passHistories && state.passHistories.length) {
      state.passHistories = state.passHistories.concat(passesCollection)
    } else {
      state.passHistories = passesCollection
    }
  },
  UPDATE_PASS_HISTORY_COMMENTS(state, passObj) {
    if (state.passHistories) {
      const newCollection = state.passHistories.map((pass) => {
        if (pass.id === passObj.id) {
          pass.comments = passObj.comments
        }
        return pass
      })
      state.passHistories = newCollection
    }
  },
  UPDATE_PASS_HISTORY_COMMENT(state, passObj) {
    if (state.passHistories) {
      const newCollection = state.passHistories.map((pass) => {
        if (pass.id === passObj.id) {
          pass.latest_comment = { comment: passObj.comment || null }
          pass.comments_count = passObj.comment
            ? pass.comments_count + 1
            : pass.comments_count
        }
        return pass
      })
      state.passHistories = newCollection
    }
  },
  SET_PASSES_TIME_SETTING(state, timeSetting) {
    state.passesTimeSetting = timeSetting
  },
  SET_PASS_LIMIT(state, pass_limit) {
    state.pass_limit = pass_limit
  },
  SET_APP_PASS_LIMIT(state, app_pass_limit) {
    state.app_pass_limit = app_pass_limit
  },
  SET_ACTIVE_PASS(state, activePass) {
    state.activePass = { ...state.activePass, ...activePass }
  },
  SET_TRANSPARENCIES(state, transparenciesCollection) {
    state.transparencies = transparenciesCollection
  },
  PUSH_TRANSPARENCIES(state, transparenciesCollection) {
    if (state.transparencies && state.transparencies.length) {
      state.transparencies = state.transparencies.concat(
        transparenciesCollection
      )
    } else {
      state.transparencies = transparenciesCollection
    }
  },
  ADD_TRANSPARENCY(state, newTransparency) {
    state.transparencies.transparency_users.data.push(newTransparency)
  },
  SET_TRANSPARENCY_STATUS(state, newStatus) {
    state.transparencyStatus = newStatus
  },
  UPDATE_PASS_HISTORY(state, passObj) {
    passObj.badge_flags = helpers.getBadgeFlags(passObj)
    const newCollection = state.passHistories.map((pass) => {
      if (pass.id === passObj.id) {
        return passObj
      }
      return pass
    })
    state.passHistories = newCollection
  },
  SET_STUDENT_PASS_HISTORIES(state, passHistories) {
    state.studentPassHistories = passHistories
  },
  SET_ACTIVE_PASS_FLAGS(state, data) {
    if (data) {
      state.activePass.flags = data
    }
  }
}

const getters = {
  passes(state) {
    return state.passes
  },
  formListPeriods(state) {
    if (state.periods) {
      return state.periods.map((period) => {
        return {
          value: [period.id],
          label: period.name,
          attrs: {}
        }
      })
    }
  },
  formSelectTransparencyUsers(state) {
    if (state.transparencies) {
      return state.transparencies.users.map((user) => {
        return { value: user.id, label: user.first_name + " " + user.last_name }
      })
    }
  },
  passHistories(state) {
    return state.passHistories
  },
  passesTimeSettings(state) {
    return state.passesTimeSetting
  },
  pass_limit(state) {
    return state.pass_limit
  },
  app_pass_limit(state) {
    return state.app_pass_limit
  },
  activePass(state) {
    return state.activePass
  },
  transparencies(state) {
    return state.transparencies
  },
  transparencyUserIDs(state) {
    return state.transparencies.map((user) => {
      return user.user_id
    })
  },
  transparencyStatus(state) {
    return state.transparencyStatus
  },
  studentPassHistories(state) {
    return state.studentPassHistories
  }
}

const actions = {
  getPassesTimeSetting(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/passes/time")
        .then((response) => {
          const data = response.data.data
          if (data) {
            context.commit("SET_PASSES_TIME_SETTING", data.time_settings)
            if (
              data &&
              data.time_settings &&
              data.time_settings.polarity_message
            ) {
              context.commit(
                "polarities/SET_POLARITY_MESSAGE",
                data.time_settings.polarity_message,
                { root: true }
              )
            } else {
              context.commit(
                "polarities/SET_POLARITY_MESSAGE",
                "POLARITY_MESSAGE",
                { root: true }
              )
            }
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setPassesTimeSetting(context, form) {
    return new Promise((resolve, reject) => {
      axios
        .put("/admin/passes/time", form)
        .then((response) => {
          context.dispatch("getPassesTimeSetting")
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPassHistories(context, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/passes-histories/filter", {
          params: filterData
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCSVPassHistoriesExport(context, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/passes-histories/exports/csv", {
          params: filterData
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  passHistoryCsvDownload(_context, filename) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/passes-histories/exports/download/${filename}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteCsvFile(_context, filename) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/passes-histories/exports/delete/${filename}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPasses(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/passes`)
        .then((response) => {
          resolve(response)
          context.commit("rooms/SET_ROOMS", response.data.data.rooms, {
            root: true
          })
          context.commit("users/SET_USERS", response.data.data.users, {
            root: true
          })
          context.commit("SET_PERIODS", response.data.data.periods)
          context.commit("SET_PASSES", response.data.data.passes)
          context.commit("SET_PASS_LIMIT", response.data.data.pass_limit)
          context.commit(
            "SET_APP_PASS_LIMIT",
            response.data.data.appointment_passes
          )
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getTransparencies(context, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/transparencies`, {
          params: filterData
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createTransparency(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/transparencies`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  toggleTransparency(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/transparencies/toggle`, data)
        .then((response) => {
          context.commit("SET_TRANSPARENCY_STATUS", data.status)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteTransparency(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/transparencies/${data.transparency_id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  exportTransparencies(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/transparencies/csv`, { params: params })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createPass(context, pass) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/passes`, pass)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createLatePass(context, pass) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post(`/passes/slp`, pass)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  changePassHistoryTime(context, data) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .put(`/admin/passes-histories/${data.passID}/edit-time`, data.newTime)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getStudentPassHistory(context, userID) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/passes/history/${userID}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getStudentPassHistoryAsStudent(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/passes/history`)
        .then((response) => {
          resolve(response)
          if (response.data.data) {
            context.commit("SET_STUDENT_PASS_HISTORIES", response.data.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getStudentActivePass(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/passes/current`)
        .then((response) => {
          const data = response.data.data
          if (data && data.id) {
            context.commit("SET_ACTIVE_PASS", data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancelActivePass(context, pass) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/passes/${pass}/cancel`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancelPass(context, pass) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/passes/${pass}/cancel`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  acknowledgeStudentAppointmentPass(context, pass) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/passes/appointments/${pass}/acknowledge`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setPassStatus(context, pass) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/passes/${pass.id}`, pass.data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePass(context, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/passes/${id}`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  autoApprovePass(context, { id, action }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/passes/${id}/auto-approve`, { action })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  autoCheckIn(context, { id, roomPin }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/passes/${id}/auto-checkin`, { room_pin: roomPin })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getActiveFlags(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/passes/flags/fields`, {
          params: params
        })
        .then((response) => {
          const data = response.data
          if (data) {
            context.commit("SET_ACTIVE_PASS_FLAGS", data)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
