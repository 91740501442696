import axios from "axios"
import localforage from "localforage"
import memoryDriver from "localforage-memoryStorageDriver"
import { setupCache, setup } from "axios-cache-adapter"
import tokenService from "@/helpers/tokenService.js"
import appConfig from "@/helpers/appConfig.js"

if (!appConfig.isProductionBuild) {
  axios.defaults.headers.common["Authorization"] =
    appConfig.axios().Authorization
}
localforage.defineDriver(memoryDriver)
// Create `localforage` instance
const forageStore = localforage.createInstance({
  // List of drivers used
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver
  ],
  // Prefix all storage keys to prevent conflicts
  name: "e-hallpass-" + tokenService.getToken()
})
const configure = async function () {
  // Register the custom `memoryDriver` to `localforage`
  await localforage.defineDriver(memoryDriver)

  // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
  return setup({
    // `axios` options
    baseURL: appConfig.api + "v5",
    headers: {
      common: {
        Accept: "application/json, text/plain, */*"
      }
    },
    withCredentials: appConfig.axios().withCredentials,
    //Define Cache setting and invalidation
    cache: {
      maxAge: 30 * 60 * 1000,
      exclude: { query: false },
      store: forageStore,
      invalidate: async (config, request) => {
        if (request.clearCacheEntry) {
          await config.store.removeItem(config.uuid)
        }
      }
    }
  })
}
const configure6 = async function () {
  // Register the custom `memoryDriver` to `localforage`
  await localforage.defineDriver(memoryDriver)

  // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
  return setup({
    // `axios` options
    baseURL: appConfig.api + "v6",
    headers: {
      common: {
        Accept: "application/json, text/plain, */*"
      }
    },
    withCredentials: appConfig.axios().withCredentials,
    //Define Cache setting and invalidation
    cache: {
      maxAge: 30 * 60 * 1000,
      exclude: { query: false },
      store: forageStore,
      invalidate: async (config, request) => {
        if (request.clearCacheEntry) {
          await config.store.removeItem(config.uuid)
        }
      }
    }
  })
}
const configure7 = async function () {
  // Register the custom `memoryDriver` to `localforage`
  await localforage.defineDriver(memoryDriver)

  // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
  return setup({
    // `axios` options
    baseURL: appConfig.api + "v7",
    headers: {
      common: {
        Accept: "application/json, text/plain, */*"
      }
    },
    withCredentials: appConfig.axios().withCredentials,
    //Define Cache setting and invalidation
    cache: {
      maxAge: 30 * 60 * 1000,
      exclude: { query: false },
      store: forageStore,
      invalidate: async (config, request) => {
        if (request.clearCacheEntry) {
          await config.store.removeItem(config.uuid)
        }
      }
    }
  })
}
window.lf = forageStore
const cache = setupCache({
  maxAge: 5 * 60 * 1000,
  exclude: { query: false },
  invalidate: async (config, request) => {
    if (request.clearCacheEntry) {
      await config.store.removeItem(config.uuid)
    }
  }
})

const ai = {
  baseURL: appConfig.api + "v5",
  headers: {
    common: {}
  },
  withCredentials: appConfig.axios().withCredentials,
  adapter: cache.adapter
}
ai.headers.common["Accept"] = "application/json, text/plain, */*"

const api = axios.create(ai)

//api - in memory cache (cleared when you close the browser)
//configure - local storage cache
const ai6 = {
  baseURL: appConfig.api + "v6",
  headers: {
    common: {}
  },
  withCredentials: appConfig.axios().withCredentials,
  adapter: cache.adapter
}
ai6.headers.common["Accept"] = "application/json, text/plain, */*"

const api6 = axios.create(ai6)

const aiNoCachev5 = {
  baseURL: appConfig.api + "v5",
  headers: {
    common: {
      Accept: "application/json, text/plain, */*",
      "Cache-Control": "no-cache"
    }
  },
  withCredentials: appConfig.axios().withCredentials
}
const apiV5nocache = axios.create(aiNoCachev5)

const aiNoCachev6 = {
  baseURL: appConfig.api + "v6",
  headers: {
    common: {
      Accept: "application/json, text/plain, */*"
    }
  },
  withCredentials: appConfig.axios().withCredentials
}
const apiV6nocache = axios.create(aiNoCachev6)

const apiNoCachev7 = {
  baseURL: appConfig.api + "v7",
  headers: {
    common: {
      Accept: "application/json, text/plain, */*"
    }
  },
  withCredentials: appConfig.axios().withCredentials
}
const apiV7nocache = axios.create(apiNoCachev7)

const apiNoCachev8 = {
  baseURL: appConfig.api + "v8",
  headers: {
    common: {
      Accept: "application/json, text/plain, */*"
    }
  },
  withCredentials: appConfig.axios().withCredentials
}
const apiV8nocache = axios.create(apiNoCachev8)

const localDataNoCache = {
  headers: {
    common: {
      Accept: "application/json, text/plain, */*"
    }
  },
  withCredentials: appConfig.axios().withCredentials
}
const localNoCache = axios.create(localDataNoCache)

const visitorNoCacheV1 = {
  baseURL: appConfig.api + "visitor/v1",
  headers: {
    common: {
      Accept: "application/json, text/plain, */*"
    }
  },
  withCredentials: appConfig.axios().withCredentials
}

const visitorApiV1NoCache = axios.create(visitorNoCacheV1)

export {
  api,
  api6,
  configure,
  configure6,
  configure7,
  forageStore,
  apiV5nocache,
  apiV6nocache,
  apiV7nocache,
  apiV8nocache,
  localNoCache,
  visitorApiV1NoCache
}
